enum CountryEnum {
  FRANCE = 'France',
  ITALIE = 'Italie',
  LUXEMBOURG = 'Luxembourg',
  ESPAGNE = 'Espagne',
  MONACO = 'Principauté de Monaco',
}

export class CountryHelper {
  static allCountries = (): CountryEnum[] => Object.values(CountryEnum)
}

export default CountryEnum
